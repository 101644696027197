import React from "react"

import Layout from "../components/layout"

import ImageMeta from "../components/ImageMeta"

import { SharedStateProvider } from "../components/SharedState/PageSharedState"

import SEO from "../components/seo"

import Button from "@bit/azheng.joshua-tree.button"
import TheaterVideo from "@components/Theater/TheaterVideo";
import { Link } from "gatsby"


const ExparelPage = () => {
  let language = "en"

  // active, closed
  let programStatus = "closed";

  return (
    <SharedStateProvider>
      <Layout pageTitle="procedure-page" language={language}>
        <SEO
          title="EXPAREL® | Gresham, The Dalles, Milwaukie, OR, and Camas, WA"
          description="Our oral surgery practice provides EXPAREL®, the non-opioid alternative to pain relief following surgery. EXPAREL is provided in all of our offices located in Gresham, The Dalles, Milwaukie, OR, and Camas, WA."
        />
        {/* <Button 
          buttonText={language === "es" ? "ATRÁS" : "BACK"}
          goBack
          /> */}

        <div className="joshua-tree-content">
          <div className="exparel-banner">
          <ImageMeta
            cloudName="nuvolum"
            publicId="BOMS/DEV/exparel-logo"
            responsive
          />
          </div>
          <div className="columns top-section white-back" style={{paddingTop: 0}}>
            <div className="column is-4"></div>
            <div className="column">
              {/* <h1>EXPAREL<sup>®</sup></h1> */}
              <h1 className="h2-style">Enhanced Recovery Through Non-Opioid Pain Relief</h1>
              <p>Beacon Oral & Maxillofacial Surgeons is taking action to combat the ongoing opioid epidemic. Previously, narcotic pain medications were the only option to alleviate serious pain following a surgical procedure. Now with <a href="https://www.exparel.com/patient/non-opioid-pain-medication" title='Visit the offificial site for EXPAREL®' target="_blank">EXPAREL</a>, we can provide patients with long-lasting pain relief that reduces or eliminates the need for opioid pain medication.</p>

              <p>During your surgical procedure, your oral surgeon can administer EXPAREL directly to the surgical site. It is a single injection that lasts for up to 96 hours and works by numbing the nerves in that area. The first few days following surgery can be uncomfortable, but with EXPAREL, you can focus on recovering without worrying about pain. In addition to EXPAREL, our practice offers a variety of <a href="/anesthesia-options/" title="Learn more about our anesthesia and sedation options">anesthesia and sedation options</a> to choose from when undergoing any type of procedure.</p>


              <h3>Combating the Opioid Epidemic</h3>
              <p>Opioids are often prescribed after surgery to help manage post-operative pain. There are tighter restrictions surrounding their use, however, millions of Americans abuse opioid medication. This highly addictive option can lead to drug addiction and a spectrum of side effects including nausea, vomiting, depression, confusion, drowsiness, and constipation. The abuse of opioids can even lead to death.</p>

              <p>Beacon Oral & Maxillofacial Surgeons provides EXPAREL at all four of our office locations. When administered during surgery, EXPAREL can reduce or even eliminate the need for opioid pain medication altogether, resulting in a faster and healthier recovery for our patients.</p>

              <h3>Benefits of EXPAREL Pain Relief</h3>
              <ul>
                <li>DepoFoam<sup>®</sup> drug delivering technology gradually releases pain-relieving particles over time, making the numbing effect last for up to 96 hours.</li>
                <li>EXPAREL can be administered following any type of surgical procedure.</li>
                <li>Reduce or eliminate the need for opioid pain medication after surgery.</li>
                <li>Significantly better pain control leads to a faster and easier recovery.</li>
              </ul>

              <h3>Experience a Better Recovery</h3>
              <p>Many patients are concerned about how they will manage pain after surgery. Beacon Oral & Maxillofacial Surgeons is doing everything possible to keep patients healthy and safe. We provide EXPAREL at our offices in Gresham, The Dalles, Milwaukie, OR, and Camas, WA. <Link to="/contact-us/">Contact</Link> our practice to learn more about EXPAREL or to schedule an appointment with one of our board-certified oral surgeons, Dr. Lieblick and Dr. Rehrer.</p>

            </div>
            <div className="column is-4"></div>
          </div>
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export default ExparelPage